import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Page } from '../../commons/components/Page';

export const HelpPage = (props) => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id="help.title" /></h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6">
            <h2 className='section-title fs-5'><FormattedMessage id="help.subtitle" /></h2>
            <div className='section-content'>
              <p><FormattedMessage id="help.contact" /></p>
              <ul>
                <li><i className="fa fa-envelope me-2" aria-hidden="true"/><a className='text-decoration-none' href="mailto:nina.creplet@zerocopy.be">nina.creplet@zerocopy.be</a></li>
                <li><i className="fa fa-phone me-2" aria-hidden="true" /><a className='text-decoration-none' href="tel:+32496070872">+32 496 07 08 72</a></li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}